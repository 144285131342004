<script setup>
import GroupItem from '@/Components/Chat/Sidebar/GroupItem.vue';
import { auth, db } from '@/firebaseConfig';
import { useChatStore } from '@/stores/chat.js';
import { collection, doc, getDocs, limit, query, where, writeBatch } from '@firebase/firestore';
import { usePage } from '@inertiajs/vue3';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { refDebounced, tryOnBeforeMount, useInfiniteScroll } from '@vueuse/core';
import { computed, ref } from 'vue';

const chat = useChatStore();

const searchClient = instantMeiliSearch(
    import.meta.env.VITE_MEILISEARCH_FRONTEND_HOST,
    import.meta.env.VITE_MEILISEARCH_FRONTEND_KEY,
    {
        primaryKey: 'id',
    }
);

const indexName = ref('');
const filters = ref('');
const attributesToRetrieve = ref(['id', 'nickname', 'last_name', 'image_url']);

const transformItems = (items) => {
    return items.map((item) => ({
        ...item,
        displayName: `${item.nickname} ${item.last_name}`,
        imageUrl: item.image_url,
    }));
};

const scrollElement = ref(null);
const startingNewChat = ref(false);
const search = ref('');
const debouncedSearch = refDebounced(search, 500);

const filteredGroups = computed(() => {
    if (debouncedSearch.value === '') {
        return chat.groups;
    }

    return chat.groups.filter((group) => {
        return group.displayName.toLowerCase().includes(search.value.toLowerCase());
    });
});

const filteredFilters = computed(() => {
    if (chat.excludeFromNewGroup.length === 0) {
        return filters.value;
    }

    const excludeFilter = chat.excludeFromNewGroup.reduce((acc, exclude) => {
        if (acc === '' && filters.value === '') {
            return `id != '${exclude}'`;
        }

        return acc + ` AND id != '${exclude}'`;
    }, '');

    return filters.value + excludeFilter;
});

tryOnBeforeMount(() => {
    const scoutPrefix = usePage().props.scout_prefix ?? '';

    if (chat.type === 'employer') {
        indexName.value = `${scoutPrefix}users`;
        filters.value = 'type = freelancer OR type = temp_worker';
        return;
    }

    indexName.value = `${scoutPrefix}locations`;
});

useInfiniteScroll(
    scrollElement,
    async () => {
        if (startingNewChat.value === true || chat.lastGroupDocument === null) {
            return;
        }

        await chat.getGroups(true);
    },
    { distance: 75 }
);

const searchForNewGroup = async () => {
    if (startingNewChat.value === true) {
        startingNewChat.value = false;
        return;
    }

    startingNewChat.value = true;
};

const startNewGroup = async (userOrLocation) => {
    const groupsCollectionRef = collection(db, 'groups');

    // Check if group was created before, as this may have happened
    let q;
    if (chat.type === 'employer') {
        q = query(
            groupsCollectionRef,
            where('locationId', '==', usePage().props.teamable.id),
            where('members', 'array-contains', userOrLocation.id),
            limit(1)
        );
    } else {
        q = query(
            groupsCollectionRef,
            where('locationId', '==', userOrLocation.id),
            where('members', 'array-contains', auth.currentUser.uid),
            limit(1)
        );
    }

    const querySnapshop = await getDocs(q);
    const groupDocRef = doc(collection(db, 'groups'));

    if (querySnapshop.size > 0) {
        const group = querySnapshop.docs[0];

        chat.selectGroup(group.id);

        chat.excludeFromNewGroup.push(userOrLocation.id);

        startingNewChat.value = false;

        return;
    }

    const batch = writeBatch(db);

    if (chat.type === 'employer') {
        batch.set(groupDocRef, {
            members: [userOrLocation.id],
            locationId: usePage().props.teamable.id,
            created: false,
        });

        const ourUserGroupRef = doc(db, 'users', auth.currentUser.uid, 'groups', groupDocRef.id);

        batch.set(ourUserGroupRef, {
            id: groupDocRef.id,
            displayName: userOrLocation.displayName,
            locationId: usePage().props.teamable.id,
            imageUrl: userOrLocation.imageUrl,
            message: '',
            sentAt: null,
            sentById: null,
        });
    } else {
        batch.set(groupDocRef, {
            members: [auth.currentUser.uid],
            locationId: userOrLocation.id,
        });

        const ourUserGroupRef = doc(db, 'users', auth.currentUser.uid, 'groups', groupDocRef.id);

        batch.set(ourUserGroupRef, {
            id: groupDocRef.id,
            displayName: userOrLocation.displayName,
            locationId: userOrLocation.id,
            imageUrl: userOrLocation.imageUrl,
            message: '',
            sentAt: null,
            sentById: null,
        });
    }

    await batch.commit();

    chat.selectGroup(groupDocRef.id);

    chat.excludeFromNewGroup.push(userOrLocation.id);

    startingNewChat.value = false;
};

const selectGroup = (group) => {
    chat.selectGroup(group.id, group?.sentById !== auth.currentUser.uid && group.read === false);
};
</script>

<template>
    <ais-instant-search class="flex flex-col py-2 space-y-2" :search-client="searchClient" :index-name="indexName">
        <ais-configure :filters="filteredFilters" :attributesToRetrieve="attributesToRetrieve" />
        <div class="flex flex-row items-center justify-between space-x-4">
            <ais-search-box
                v-if="startingNewChat"
                :placeholder="$t('Search')"
                :class-names="{
                    'ais-SearchBox': 'rounded-md shadow-sm w-full',
                    'ais-SearchBox-input':
                        'block w-full !pl-4 py-3 !border-[#F7F7F7] !border !rounded focus:!ring-indigo-500 focus:!border-indigo-500 !placeholder-gray-input !text-gray-input !bg-gray-lighter',
                }"
            >
            </ais-search-box>
            <div v-else class="relative w-full rounded-md shadow-sm">
                <input
                    type="text"
                    name="search"
                    id="search"
                    v-model.trim="search"
                    class="block w-full pl-4 py-3 border-[#F7F7F7] border rounded focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-input text-gray-input bg-gray-lighter"
                    :placeholder="$t('Search')"
                />
            </div>

            <button
                type="button"
                :aria-label="$t('Start new chat')"
                class="flex flex-row items-center justify-center pr-6"
                @click="searchForNewGroup()"
            >
                <div class="p-2 rounded bg-gray-lighter">
                    <img src="@/../img/icon/plus-gray.svg" alt="" />
                </div>
            </button>
        </div>

        <div class="flex flex-col overflow-auto h-140" ref="scrollElement">
            <ais-infinite-hits v-if="startingNewChat" :transform-items="transformItems">
                <template v-slot:item="{ item }">
                    <GroupItem
                        :key="item.id"
                        :group="item"
                        :search-logic="true"
                        @click="startNewGroup(item)"
                        class="w-full"
                    />
                </template>
            </ais-infinite-hits>

            <GroupItem
                v-for="group in filteredGroups"
                v-if="!startingNewChat"
                :key="group.id"
                :group="group"
                @click="selectGroup(group)"
            />
        </div>
    </ais-instant-search>
</template>

<style>
@import 'instantsearch.css/themes/satellite-min.css';

.ais-SearchBox-form {
    font-size: unset;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
    padding: unset;
    box-shadow: unset;
}

.ais-SearchBox-input {
    box-shadow: unset;
}

.ais-SearchBox-submitIcon path .ais-SearchBox-resetIcon path {
    fill: #5c8938;
}
.ais-SearchBox-form:before {
    display: none;
}
</style>
