<script setup>
import GroupItem from '@/Components/Chat/Sidebar/GroupItem.vue';
import { useChatStore } from '@/stores/chat.js';
import { usePage } from '@inertiajs/vue3';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { get, refDebounced, set, tryOnBeforeMount, useInfiniteScroll } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const chat = useChatStore();

const searchClient = instantMeiliSearch(
    import.meta.env.VITE_MEILISEARCH_FRONTEND_HOST,
    import.meta.env.VITE_MEILISEARCH_FRONTEND_KEY,
    {
        primaryKey: 'id',
    }
);
const instantSearch = ref({
    indexName: '',
    filters: '',
});
const scoutPrefix = ref('');
const attributesToRetrieve = ref(['id', 'nickname', 'image_url']);
const transformItems = (items) => {
    return items.map((item) => {
        let displayName = item.nickname;

        if (get(base, 'type') === 'workers') {
            displayName = displayName + ' ' + item.last_name;
        }

        return {
            ...item,
            displayName: displayName,
            imageUrl: item.image_url,
        };
    });
};

const base = ref({
    type: '',
    id: '',
});

const scrollElement = ref(null);
const selectingBase = ref(false);
const selectingGroup = ref(false);
const newGroups = ref([]);
const search = ref('');
const debouncedSearch = refDebounced(search, 500);
const newLastDocument = ref(null);

const helperText = computed(() => {
    if (selectingBase.value === true) {
        return t('Step {number}', { number: 1 }) + ': ' + t('Select Location/Worker');
    }

    if (selectingGroup.value === true) {
        if (get(base, 'type') === 'locations') {
            return t('Step {number}', { number: 2 }) + ': ' + t('Select Location');
        }

        return t('Step {number}', { number: 2 }) + ': ' + t('Select Worker');
    }

    return '';
});

const filteredGroups = computed(() => {
    if (debouncedSearch.value === '') {
        return chat.groups;
    }

    return chat.groups.filter((group) => {
        return group.displayName.toLowerCase().includes(search.value.toLowerCase());
    });
});

tryOnBeforeMount(() => {
    scoutPrefix.value = usePage().props.scout_prefix ?? '';

    setBaseSearch('locations', true);
});

useInfiniteScroll(
    scrollElement,
    async () => {
        if (selectingBase.value === true || chat.lastGroupDocument === null) {
            return;
        }

        await chat.getGroupsForStaff(base.value.id, base.value.type, true);
    },
    { distance: 1 }
);

const setBaseSearch = async (type) => {
    set(base, {
        type,
        id: '',
    });
    newGroups.value = [];
    search.value = '';
    newLastDocument.value = null;
    selectingBase.value = true;
    selectingGroup.value = false;

    if (type === 'locations') {
        set(attributesToRetrieve, ['id', 'nickname', 'image_url']);
        set(instantSearch, {
            indexName: `${scoutPrefix.value}locations`,
            filters: '',
        });

        return;
    }

    set(attributesToRetrieve, ['id', 'nickname', 'last_name', 'image_url']);

    set(instantSearch, {
        indexName: `${scoutPrefix.value}users`,
        filters: 'type = freelancer OR type = temp_worker',
    });
};

const selectBase = (val) => {
    base.value.id = val.id;
    selectingBase.value = false;
    selectingGroup.value = true;
    chat.getGroupsForStaff(val.id, base.value.type);
};

const selectGroup = (group) => {
    let memberIds;
    if (group.members) {
        memberIds = group.members.map((member) => {
            return member.id;
        });
    }
    chat.selectGroup(group.id, false, memberIds);
};

const tabs = [
    { name: 'locations', label: t('Locations') },
    { name: 'workers', label: t('Workers') },
];
</script>

<template>
    <ais-instant-search
        class="flex flex-col py-2 space-y-2"
        :search-client="searchClient"
        :index-name="instantSearch.indexName"
        :key="instantSearch.indexName"
    >
        <ais-configure :filters="instantSearch.filters" :attributesToRetrieve="attributesToRetrieve" />

        <div class="flex flex-col space-y-2">
            <strong class="">{{ helperText }}</strong>
            <div class="border-b border-gray-200">
                <nav class="flex -mb-px space-x-8" aria-label="Search type">
                    <button
                        v-for="tab in tabs"
                        type="button"
                        :key="tab.name"
                        :class="[
                            base.type === tab.name
                                ? 'border-pink text-pink'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                        ]"
                        :aria-current="tab.current ? 'true' : undefined"
                        @click="setBaseSearch(tab.name)"
                    >
                        {{ tab.label }}
                    </button>
                </nav>
            </div>
        </div>

        <div class="flex flex-row items-center justify-between space-x-4">
            <ais-search-box
                v-if="selectingBase"
                :placeholder="$t('Search')"
                :class-names="{
                    'ais-SearchBox': 'rounded-md shadow-sm w-full',
                    'ais-SearchBox-input':
                        'block w-full !pl-4 py-3 !border-[#F7F7F7] !border !rounded focus:!ring-indigo-500 focus:!border-indigo-500 !placeholder-gray-input !text-gray-input !bg-gray-lighter',
                }"
            >
            </ais-search-box>
            <div v-else class="relative w-full rounded-md shadow-sm">
                <input
                    type="text"
                    name="search"
                    id="search"
                    v-model.trim="search"
                    class="block w-full pl-4 py-3 border-[#F7F7F7] border rounded focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-input text-gray-input bg-gray-lighter"
                    :placeholder="$t('Search')"
                />
            </div>

            <button
                type="button"
                :aria-label="$t('Start new chat')"
                class="flex flex-row items-center justify-center pr-6"
                @click="searchForNewGroup()"
            >
                <div class="p-2 rounded bg-gray-lighter">
                    <img src="@/../img/icon/plus-gray.svg" alt="" />
                </div>
            </button>
        </div>

        <div class="flex flex-col overflow-auto h-140" ref="scrollElement">
            <ais-infinite-hits v-if="selectingBase" :transform-items="transformItems">
                <template v-slot:item="{ item }">
                    <GroupItem
                        :key="item.id"
                        :group="item"
                        search-logic
                        staff-logic
                        @click="selectBase(item)"
                        class="w-full"
                    />
                </template>
            </ais-infinite-hits>

            <GroupItem
                v-for="group in filteredGroups"
                v-if="selectingGroup"
                :key="group.id"
                :group="group"
                @click="selectGroup(group)"
            />
        </div>
    </ais-instant-search>
</template>

<style>
@import 'instantsearch.css/themes/satellite-min.css';

.ais-SearchBox-form {
    font-size: unset;
}

.ais-Hits-item,
.ais-InfiniteHits-item {
    padding: unset;
    box-shadow: unset;
}

.ais-SearchBox-input {
    box-shadow: unset;
}

.ais-SearchBox-submitIcon path .ais-SearchBox-resetIcon path {
    fill: #5c8938;
}
.ais-SearchBox-form:before {
    display: none;
}
</style>
