<script setup>
import { useChatStore } from '@/stores/chat.js';
import { ref } from 'vue';

const chat = useChatStore();
const message = ref('');

const onSubmit = async () => {
    if (message.value.length === 0 && !chat.selectedGroup) {
        return;
    }

    chat.sendMessage(message.value);
    message.value = '';
};
</script>

<template>
    <form @submit.prevent="onSubmit()" class="flex flex-row space-x-4 pt-5 pb-4 pl-4 mr-5 pr-[14px]">
        <div class="relative w-full rounded-full shadow-sm bg-gray-lighter">
            <input
                type="text"
                name="message"
                id="message"
                v-model.trim="message"
                class="block w-full py-2 pl-7 rounded-lg focus:ring-indigo-500 focus:border-indigo-500 placeholder-[#525252] text-[#525252] pr-5"
                :placeholder="$t('Type Message...')"
                :disabled="!chat.selectedGroup"
            />
        </div>
        <button
            type="submit"
            :aria-label="$t('Send')"
            class="h-full px-5 font-semibold text-white rounded-lg disabled:hover:cursor-not-allowed bg-orange"
            :disabled="!chat.selectedGroup || message.length === 0"
        >
            {{ $t('Send') }}
        </button>
    </form>
</template>
