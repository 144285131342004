<script setup>
import { useChatStore } from '@/stores/chat.js';
import Sendbar from '@/Components/Chat/Sendbar.vue';
import { inject } from 'vue';
import fallbackImage from '@/../img/flexable-logo-sunset-small.png';
const dayjs = inject('dayjs');

const chat = useChatStore();
</script>

<template>
    <div class="relative flex flex-col w-full h-full rounded-r bg-gray-lighter">
        <div v-if="chat.type === 'employer'">
            <div
                class="z-10 p-2 md:p-4 bg-[#292929] rounded-lg text-white text-xs absolute inset-x-1 md:inset-x-2 lg:inset-x-4 top-1 md:top-2 lg:top-4"
            >
                <p>
                    {{
                        $t(
                            'PAY ATTENTION! Messages should only be used for FLEXABLE services. Use for personal purposes, recruitment or harassment of FLEXABLE is not allowed.'
                        )
                    }}
                </p>
            </div>
        </div>

        <div class="flex flex-col-reverse justify-start w-full px-8 py-4 space-y-2 space-y-reverse overflow-auto h-160">
            <div v-for="(messages, key) in chat.messages" :key="key" class="flex flex-col space-y-2">
                <span class="pb-4 text-center text-[#727272]">
                    {{ dayjs(key).format('L LT') }}
                </span>

                <div
                    v-for="message in messages"
                    :key="message.id"
                    class="flex flex-row space-x-4 max-w-[53%]"
                    :class="{
                        'self-end': message.ownMessage,
                        'self-start pb-5': !message.ownMessage,
                    }"
                >
                    <div
                        class="self-end p-2 aspect-w-1 aspect-h-1"
                        :class="!group?.imageUrl ? 'bg-gray-100' : ''"
                        v-if="!message.ownMessage"
                    >
                        <img
                            class="object-cover object-center w-full h-full rounded-full"
                            :src="message.imageUrl"
                            :alt="message.sentByName"
                            :title="message.sentByName"
                            v-if="message.imageUrl"
                            @error="message.imageUrl = fallbackImage"
                        />
                        <img
                            :src="fallbackImage"
                            :alt="message.displayName"
                            :title="message.displayName"
                            v-else
                            class="object-cover object-center w-full h-full rounded-full"
                        />
                    </div>
                    <div
                        class="relative p-4 rounded-lg"
                        :class="{
                            'bg-blue-light text-white': message.ownMessage,
                            'bg-white text-[#020047]': !message.ownMessage,
                        }"
                    >
                        <div
                            v-if="!message.ownMessage && message.sentByName != undefined"
                            class="absolute -top-3 bg-blue-light text-white py-[3px] px-[6px] rounded min-w-max"
                        >
                            {{ message.sentByName }}
                        </div>
                        <p>{{ message.message }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col justify-end" v-if="chat.type === 'employer'">
            <Sendbar />
        </div>
    </div>
</template>
