<script setup>
import { auth } from '@/firebaseConfig';
import { useChatStore } from '@/stores/chat';
import { computed, inject } from 'vue';
import fallbackImage from '@/../img/flexable-logo-sunset-small.png';

const dayjs = inject('dayjs');

const chat = useChatStore();

const props = defineProps({
    group: {
        type: Object,
        required: true,
    },
    searchLogic: {
        type: Boolean,
        default: false,
    },
    staffLogic: {
        type: Boolean,
        default: false,
    },
});

const formattedSentAt = computed(() => {
    if (!props.group.sentAt) {
        return '';
    }

    const date = dayjs(props.group.sentAt.toDate());

    if (date.isToday()) {
        return date.format('LT');
    }

    return date.format('D MMM');
});

const unread = computed(() => {
    if (props.searchLogic || props.staffLogic) {
        return false;
    }

    if (props.group?.sentById === auth.currentUser.uid) {
        return false;
    }

    return props.group?.read == false && props.group?.sentAt != null;
});
</script>

<template>
    <button
        class="relative flex flex-row items-center justify-center py-4 pl-6 pr-6 space-x-4 md:justify-start hover:bg-gray-lighter"
        :class="{ 'bg-gray-lighter': chat.selectedGroup == group.id }"
    >
        <div class="absolute bottom-3 right-6" v-show="unread">
            <div class="flex items-center justify-center w-6 h-6 p-1 text-white rounded-full bg-pink">1</div>
        </div>

        <div class="p-4 aspect-w-1 aspect-h-1" :class="!group?.imageUrl ? 'bg-gray-100' : ''">
            <img
                v-if="group.imageUrl"
                :src="group.imageUrl"
                :alt="group.displayName"
                :title="group.displayName"
                class="object-cover object-center w-full h-full rounded-full"
                @error="group.imageUrl = fallbackImage"
            />
            <img
                v-else
                :src="fallbackImage"
                :alt="group.displayName"
                :title="group.displayName"
                class="object-cover object-center w-full h-full rounded-full"
            />
        </div>

        <div class="flex flex-col overflow-x-hidden text-[#020047] justify-start items-start w-full">
            <div class="flex flex-row items-start justify-between w-full space-x-1">
                <strong class="w-full font-bold text-start">
                    {{ group.displayName }}
                </strong>

                <span class="w-full text-end" v-if="!searchLogic">
                    {{ formattedSentAt }}
                </span>
            </div>

            <p class="w-full truncate text-start" v-if="!searchLogic">
                {{ group.message }}
            </p>
        </div>
    </button>
</template>
